import React, { Component } from 'react';
import Layout from '../components/layout';
import PaddedSection from '../components/PaddedSection';
import config from '../components/config';

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      nameError: false,
      emailError: false,
      messageError: false,
      successMessage: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  changeHandler(input, value) {
    this.setState({
      [input]: value,
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    let hasErrors = false;
    if (this.state.name === '') {
      this.setState({
        nameError: true,
      });
      hasErrors = true;
    }
    if (this.state.email === '') {
      this.setState({
        emailError: true,
      });
      hasErrors = true;
    }
    if (this.state.message === '') {
      this.setState({
        messageError: true,
      });
      hasErrors = true;
    }
    if (hasErrors) return;
    const res = await fetch(`${config.backend}/user/contact-form`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: this.state.name,
        email: this.state.email,
        message: this.state.message,
      }),
    });
    const response = await res.json();
    if (response.message === 'message saved') {
      this.setState({
        successMessage: true,
      });
    }
  }

  render() {
    return (
      <Layout>
        <PaddedSection>
          <br />
          <br />
          <div style={{ minHeight: '300px' }}>
            <p>We're always happy to help with any questions comments of concerns you might have, hit us up below and we'll get back to you as soon as humanly possible :)</p>
            <form onSubmit={(e) => this.handleSubmit(e)} style={{ textAlign: 'center' }}>
              <br />
              { this.state.successMessage
                ? (
                  <>
                    <br />
                    <div className="success-div" style={{ margin: '0 1rem' }}>Message Received!</div>
                    ,
                    <br />
                  </>
                )
                : null}
              <input id="name-input" type="text" placeholder="First Name" className={`signup-input-element ${this.state.nameError ? 'signup-input-element-error' : ''}`} onChange={(e) => { this.changeHandler('name', e.target.value); }} />
              <br />
              <br />
              <input type="email" id="email-input" placeholder="Email" className={`signup-input-element ${this.state.emailError ? 'signup-input-element-error' : ''}`} onChange={(e) => { this.changeHandler('email', e.target.value); }} />
              <br />
              <br />
              <textarea id="message-textarea" placeholder="Message" className={`signup-input-element ${this.state.messageError ? 'signup-input-element-error' : ''}`} onChange={(e) => { this.changeHandler('message', e.target.value); }} />
              <br />
              <br />
              <button type="submit" className="main-nav-link">Send!</button>
            </form>
            <p>Or Feel Free To</p>
            <a href="mailto:info@pixopixa.com">Send us an email!</a>
            <br />
            <br />
            <a href="https://www.facebook.com/PixoPixaGames/">Or hit us up on Facebook</a>
          </div>
          <br />
          <br />
        </PaddedSection>
      </Layout>
    );
  }
}
export default ContactUs;
